import { useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  Button,
  Form,
  Input,
  Divider,
  Row,
  Col,
  Select,
  Cascader,
  Modal,
  Tooltip,
  Badge
} from "antd";
import {
  CustomerServiceFilled,
  // CreditCardFilled,
  PhoneFilled,
  ShopFilled,
  ShoppingFilled,
  HomeOutlined,
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  ShopOutlined,
  // CreditCardOutlined,
  NumberOutlined,
  BankOutlined,
  CopyOutlined,
} from "@ant-design/icons";

import { useAppContext } from "../context/appContext";
import styles from "../styles/FormApp.module.css";
import { flushSync } from "react-dom";

export default function FormApp() {
  const [submitButtonDisable, setSubmitButtonDisable] = useState(true);
  // const [cardValidate, setCardValidate] = useState("");
  // const [ccvValidate, setCcvValidate] = useState("");
  const formRef = useRef(null);
  const submitButton = useRef();
  const [form] = Form.useForm();
  const { scripterData, cookies, messageData, activecallkeyData, setScripterData } =
    useAppContext();
  const isFormSubmitted = useRef(false); 

  const {
    StreetName, //check
    Region, //check
    City, //check
    PostalCode, //check
    calledNumber, // store Number Api'ya calledNumber şeklinde gönderilmeli
    Secondary_DID, // check 
    StoreID, //check
    CashLimit, //check
    ServiceMethodEstimatedWaitMinutes_Delivery_Min, //check
    ServiceMethodEstimatedWaitMinutes_Delivery_Max, //check
    ServiceMethodEstimatedWaitMinutes_Carryout_Min, //check
    ServiceMethodEstimatedWaitMinutes_Carryout_Max, //check
    name, // check
    callerNumber, // check
    stringAddress, // check
    // type,
    employeId, //tenantdan gelen parametre kontrol edilmeli
    contactId, //active call key
    tables_storeid,
    tables_streetname,
    tables_city,
    tables_secondary_did,
    tables_region,
    tables_postalcode,
  } = scripterData;

  // const { creditCardNumberStatus, ccvStatus } = creditCardStatusData;
  
  useEffect(() => {
    form.setFieldsValue({
      streetName: StreetName || tables_streetname,
      region: Region || tables_region,
      city: City || tables_city,
      zipCode: PostalCode || tables_postalcode,
      cashLimit: CashLimit,
      storeDID: Secondary_DID || tables_secondary_did,
      storeId: StoreID || tables_storeid,
      phoneNumber: calledNumber,
      numberType: "mobile", // static
      deliveryMin: ServiceMethodEstimatedWaitMinutes_Delivery_Min,
      deliveryMax: ServiceMethodEstimatedWaitMinutes_Delivery_Max,
      carryOutMin: ServiceMethodEstimatedWaitMinutes_Carryout_Min,
      carryOutMax: ServiceMethodEstimatedWaitMinutes_Carryout_Max,
      customerName: name, //check
      customerPhoneNumber: callerNumber,
      ldAdress: stringAddress, //check
      agentName: cookies.email,
      employeeId: employeId,
      contactId: contactId,
    });
  }, [scripterData]);

  // useEffect(() => {
  //   form.setFieldsValue({
  //     ivrCc: creditCardNumberStatus,
  //     ivrCvv: ccvStatus,
  //   });

  //   if (creditCardNumberStatus == "true") {
  //     setCardValidate("success");
  //   } else if (creditCardNumberStatus == "false") {
  //     setCardValidate("error");
  //   } else {
  //     setCardValidate("");
  //   }

  //   if (ccvStatus == "true") {
  //     setCcvValidate("success");
  //   } else if (ccvStatus == "false") {
  //     setCcvValidate("error");
  //   } else {
  //     setCcvValidate("");
  //   }
  // }, [creditCardStatusData]);

  useEffect(() => {
    form.setFieldsValue({
      agentStatus: messageData.agentStatus,
    });
    if (messageData.type === "connected") {
      setSubmitButtonDisable(false);
      if (isFormSubmitted.current) {
        isFormSubmitted.current = false;
      }
    }
    //if (messageData.type === "disconnected") setSubmitButtonDisable(false);
    // if (messageData.type === "destroyed") setSubmitButtonDisable(true);
    if (messageData.type === "destroyed") {

      flushSync(() => {
        setSubmitButtonDisable(prev => {
          if (!prev) {
            onFinish("flush");
            return true
          }
          return prev;
        })
      })

   
    } 
    // if (messageData.type === "destroyed" && !submitButtonDisable) {
    //   if (submitButton.current) {
    //     submitButton.current.click();
    //   }
    //   messageData.type = "";
    // }

    // const updateMessage = async () => {
    //   return await messageData;
    // };

    // updateMessage()
    //   .then((data) => {
    //     form.setFieldsValue({
    //       agentStatus: data.agentStatus,
    //     });
    //     if (data.type === "connected") setSubmitButtonDisable(false);
    //     console.log("formdata", data);
    //     if (data.type === "destroyed" && !submitButtonDisable) {
    //       console.log("burda--------ifff");
    //       if (submitButton.current) {
    //         submitButton.current.click();
    //       }
    //       data.type = "";
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("Form update message error ", error);
    //   });
  }, [messageData]);

  const successMessage = () => {
    Modal.success({
      title: "Notification",
      content: (
        <div>
          <p>The form submitted successfully..</p>
        </div>
      ),
      onOk() {},
    });
  };

  const warningMessage = (content) => {
    Modal.warning({
      title: "Warning Notification",
      content: content || "An unexpected situation has occurred!",
    });
  };

  const errorMessage = (content) => {
    Modal.error({
      title: "Error Notification",
      content: content || "An unexpected situation has occurred!",
    });
  };

  const onFinish = async (e) => {
    if (isFormSubmitted?.current) return;
    isFormSubmitted.current = true;
    
    if (typeof e !== "string") {
      flushSync(() => {
        setSubmitButtonDisable(prev => true)
      })
      e?.preventDefault();
    }
 
    let formValues = form.getFieldsValue();
    formValues.tagStatusAssignedBy = "Agent"
    if (formValues.orderStatus === undefined) {
      formValues.orderStatus = ['Other', 'No Disposition Selected']
    }

    if (e?.type !== "click") {
      formValues.tagStatusAssignedBy = "System"
    }

    if (scripterData?.["intelepeer-uui"]) {
      formValues["intelepeer-uui"] = scripterData?.["intelepeer-uui"];
      formValues["int_store"] = scripterData.int_store;
      formValues["int_phone"] = scripterData.int_phone;
      formValues["int_name"] = scripterData.int_name;
      formValues["int_address"] = scripterData.int_address;
      formValues["int_orderType"] = scripterData.int_orderType;
      formValues["int_summary"] = scripterData.int_summary;
      formValues["int_utterance"] = scripterData.int_utterance;
      formValues["int_callType"] = scripterData.int_callType;
      formValues["int_paymentType"] = scripterData.int_paymentType;
    }

    formValues["connected"] = scripterData?.connected
    formValues["disconnected"] = scripterData?.disconnected
    formValues["destroyed"] = scripterData?.destroyed
    setScripterData({});

    try {
      const response = await axios({
        url: "/api/sendScripterData",
        method: "POST",
        data: { session: cookies.session, ...formValues },
        params : { contactId: activecallkeyData },
        timeout: 7000,
      });
      if (response.status == 200 && response.data && response.data.success) {
        console.log("form resp -> ", response.data);
        //setSubmitButtonDisable(true);
        successMessage();
        form.resetFields();
        // setCardValidate("");
        // setCcvValidate("");
        // return;
      }

      form.resetFields();
      // setCardValidate("");
      // setCcvValidate("");
      // warningMessage(response.data.message);
    } catch (error) {
      console.log("form catch --> ", error);
      form.resetFields();
      //setSubmitButtonDisable(true);
      // setCardValidate("");
      // setCcvValidate("");
      errorMessage(error.message);
    }
    
    // let retry = 2;
    // for (let i = 0; i < retry; i++) {
    //   try {
    //     const responseStatus = await axios({
    //       url: "/api/setAgentStatus",
    //       method: "POST",
    //       params : { contactId: activecallkeyData },
    //       data: { session: cookies.session },
    //       timeout: 7000,
    //     });
    //     if (responseStatus.data.code == 200 && responseStatus.data && responseStatus.data.data && responseStatus.data.data.success) {
    //        i++;
    //       console.log("setAgentStatus resp -> ", responseStatus.data);
    //       // successMessage();
    //     }
       
    //     // warningMessage(responseStatus.data.message);
    //   } catch (error) {
    //     console.log("setAgentStatus catch --> ", error);
    //     // errorMessage(error.message);
    //   }
    // }
    let callVariablesSetWhenActiveCall = false;

    try {
      const responseSetCallVariableActiveCall = await axios({
        url: "/api/setCallVariableActiveCall",
        method: "POST",
        data: { key: activecallkeyData, orderStatus: formValues.orderStatus },
        params : { contactId: activecallkeyData },
        timeout: 7000,
      });
      if (responseSetCallVariableActiveCall.data?.code === 200 && responseSetCallVariableActiveCall.data?.data?.message === "Successful") {
        callVariablesSetWhenActiveCall = true;
      }
      console.log("responseSetCallVariableActiveCall resp -> ", responseSetCallVariableActiveCall?.data);
    } catch (error) {
      callVariablesSetWhenActiveCall = false;
      console.log("responseSetCallVariableActiveCall catch --> ", error);
    }
    
    if (!callVariablesSetWhenActiveCall) {
      try {
        const responseSetCallVariableNonActiveCall = await axios({
          url: "/api/setCallVariableNonActiveCall",
          method: "POST",
          data: { key: activecallkeyData, orderStatus: formValues.orderStatus },
          params : { contactId: activecallkeyData },
          timeout: 7000,
        });
  
        console.log("responseSetCallVariableNonActiveCall resp -> ", responseSetCallVariableNonActiveCall.data);
      } catch (error) {
        console.log("responseSetCallVariableNonActiveCall catch --> ", error);
      }
    }
  }

  return (
    <Row justify={"center"} className={styles.rowContainer}>
      <Col xs={21} md={10} lg={10} xl={8}>
        <Form
          form={form}
          ref={formRef}
          labelCol={{ sm: { span: 9 }, md: { span: 9 }, lg: { span: 7 } }}
          layout="horizontal"
          labelAlign="left"
        >
          <Divider orientation="left" plain className={styles.dividerContainer}>
            <ShopFilled />
            <span> Store Details</span>
          </Divider>

          <Form.Item
            label={
              <div>
                Address{" "}
                <CopyOutlined
                  title="copy address"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      formRef.current.getFieldValue("streetName")
                    );
                  }}
                />
              </div>
            }
            name="streetName"
            rules={[{ message: "Please input address!" }]}
          >
            <Input
              prefix={<HomeOutlined className="site-form-item-icon" />}
              placeholder="Address"
              disabled
            />
          </Form.Item>

          <Form.Item label="Region - City" style={{ marginBottom: 0 }}>
            <Form.Item
              name="region"
              style={{ display: "inline-block", width: "calc(50% - 12px)" }}
            >
              <Input placeholder="Region" disabled />
            </Form.Item>
            <span
              style={{
                display: "inline-block",
                width: "24px",
                lineHeight: "32px",
                textAlign: "center",
              }}
            >
              /
            </span>
            <Form.Item
              name="city"
              style={{ display: "inline-block", width: "calc(50% - 12px)" }}
            >
              <Input placeholder="City" disabled />
            </Form.Item>
          </Form.Item>

          <Form.Item label="Zip Code - Cash Limit" style={{ marginBottom: 0 }}>
            <Form.Item
              name="zipCode"
              style={{ display: "inline-block", width: "calc(50% - 12px)" }}
            >
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                placeholder="Zip Code"
                disabled
              />
            </Form.Item>
            <span
              style={{
                display: "inline-block",
                width: "24px",
                lineHeight: "32px",
                textAlign: "center",
              }}
            >
              -
            </span>
            <Form.Item
              name="cashLimit"
              style={{ display: "inline-block", width: "calc(50% - 12px)" }}
            >
              <Input
                prefix={<BankOutlined className="site-form-item-icon" />}
                placeholder="Cash Limit"
                disabled
              />
            </Form.Item>
          </Form.Item>

          <Form.Item label="Store DID - ID" style={{ marginBottom: 0 }}>
            <Form.Item
              name="storeDID"
              style={{ display: "inline-block", width: "calc(50% - 12px)" }}
            >
              <Input
                prefix={<ShopOutlined className="site-form-item-icon" />}
                placeholder="Store DID"
                disabled
              />
            </Form.Item>
            <span
              style={{
                display: "inline-block",
                width: "24px",
                lineHeight: "32px",
                textAlign: "center",
              }}
            >
              -
            </span>
            <Form.Item
              name="storeId"
              style={{ display: "inline-block", width: "calc(50% - 12px)" }}
            >
              <Input
                prefix={<ShopOutlined className="site-form-item-icon" />}
                placeholder="Store ID"
                disabled
              />
            </Form.Item>
          </Form.Item>

          <Form.Item
            label={
              <div>
                Phone Number - Type{" "}
                <CopyOutlined
                  title="copy phone number"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      formRef.current.getFieldValue("phoneNumber")
                    );
                  }}
                />
              </div>
            }
            style={{ marginBottom: 0 }}
          >
            <Form.Item
              name="phoneNumber"
              style={{ display: "inline-block", width: "calc(50% - 12px)" }}
            >
              <Input
                prefix={
                  <PhoneOutlined rotate={100} className="site-form-item-icon" />
                }
                placeholder="Phone Number"
                disabled
              />
            </Form.Item>
            <span
              style={{
                display: "inline-block",
                width: "24px",
                lineHeight: "32px",
                textAlign: "center",
              }}
            >
              -
            </span>
            <Form.Item
              name="numberType"
              style={{ display: "inline-block", width: "calc(50% - 12px)" }}
            >
              <Select>
                <Option value="home">Home</Option>
                <Option value="mobile">Mobile</Option>
              </Select>
            </Form.Item>
          </Form.Item>

          <Form.Item label="Delivery Min - Max" style={{ marginBottom: 0 }}>
            <Form.Item
              name="deliveryMin"
              style={{ display: "inline-block", width: "calc(50% - 12px)" }}
            >
              <Input placeholder="Delivery Min Time" disabled />
            </Form.Item>
            <span
              style={{
                display: "inline-block",
                width: "24px",
                lineHeight: "32px",
                textAlign: "center",
              }}
            >
              -
            </span>
            <Form.Item
              name="deliveryMax"
              style={{ display: "inline-block", width: "calc(50% - 12px)" }}
            >
              <Input placeholder="Delivery Max Time" disabled />
            </Form.Item>
          </Form.Item>

          <Form.Item label="Carry Out Min - Max" style={{ marginBottom: 0 }}>
            <Form.Item
              name="carryOutMin"
              style={{ display: "inline-block", width: "calc(50% - 12px)" }}
            >
              <Input placeholder="Carry out Min Time" disabled />
            </Form.Item>
            <span
              style={{
                display: "inline-block",
                width: "24px",
                lineHeight: "32px",
                textAlign: "center",
              }}
            >
              -
            </span>
            <Form.Item
              name="carryOutMax"
              style={{ display: "inline-block", width: "calc(50% - 12px)" }}
            >
              <Input placeholder="Carry out Max Time" disabled />
            </Form.Item>
          </Form.Item>

          <Divider orientation="left" plain className={styles.dividerContainer}>
            <UserOutlined />
            <span> Customer Details</span>
          </Divider>

          <Form.Item
            label="Name"
            name="customerName"
            rules={[{ message: "Please input name!" }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="No Record Found"
              disabled
            />
          </Form.Item>

          <Form.Item
            label={
              <div>
                Phone Number{" "}
                <CopyOutlined
                  title="copy customer phone number"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      formRef.current.getFieldValue("customerPhoneNumber")
                    );
                  }}
                />
              </div>
            }
            name="customerPhoneNumber"
            rules={[{ message: "Please input phone number!" }]}
          >
            <Input
              prefix={
                <PhoneOutlined rotate={100} className="site-form-item-icon" />
              }
              placeholder="Phone Number"
              disabled
            />
          </Form.Item>

          <Form.Item
            label="LD Address"
            name="ldAdress"
            rules={[{ message: "Please input LD Address!" }]}
          >
            <Input
              prefix={<HomeOutlined className="site-form-item-icon" />}
              placeholder="No Record Found"
              disabled
            />
          </Form.Item>

          <Form.Item name="notes" label="Notes">
            <Input.TextArea rows={3} />
          </Form.Item>

          {/* <Divider orientation="left" plain className={styles.dividerContainer}>
            <CreditCardFilled />
            <span> Card Capture Details</span>
          </Divider> */}

          {/* <Form.Item
            label="IVR CC Status"
            name="ivrCc"
            hasFeedback
            validateStatus={cardValidate}
          >
            <Input
              prefix={<CreditCardOutlined className="site-form-item-icon" />}
              placeholder="Credit Card Number Status"
              disabled
            />
          </Form.Item> */}

          {/* <Form.Item
            label="IVR CVV Status"
            name="ivrCvv"
            hasFeedback
            validateStatus={ccvValidate}
          >
            <Input
              prefix={<CreditCardOutlined className="site-form-item-icon" />}
              placeholder="Credit Card CVV Status"
              disabled
            />
          </Form.Item> */}

          <Divider orientation="left" plain className={styles.dividerContainer}>
            <CustomerServiceFilled />
            <span> Agent Details</span>
          </Divider>

          <Form.Item
            label="Agent Name"
            name="agentName"
            rules={[{ required: true, message: "Please input agent Name!" }]}
          >
            <Input
              prefix={<CustomerServiceFilled className="site-form-item-icon" />}
              placeholder="Agent Name"
              disabled
            />
          </Form.Item>

          <Form.Item label="Agent Status" name="agentStatus">
            <Input
              prefix={<CustomerServiceFilled className="site-form-item-icon" />}
              placeholder="Agent Status"
              disabled
            />
          </Form.Item>

          <Form.Item
            label="Employee Id"
            name="employeeId"
            rules={[{ message: "Please input emloyee id!" }]}
          >
            <Input
              prefix={<CustomerServiceFilled className="site-form-item-icon" />}
              placeholder="Employee Id"
              disabled
            />
          </Form.Item>

          <Divider orientation="left" plain className={styles.dividerContainer}>
            <PhoneFilled rotate={110} />
            <span> Contact Details</span>
          </Divider>

          <Form.Item
            label={
              <div>
                Contact Id{" "}
                <CopyOutlined
                  title="copy contact id"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      formRef.current.getFieldValue("contactId")
                    );
                  }}
                />
              </div>
            }
            name="contactId"
            rules={[{ required: true, message: "Please input contact id!" }]}
          >
            <Input
              prefix={<NumberOutlined className="site-form-item-icon" />}
              placeholder="Contact Id"
              disabled
            />
          </Form.Item>

          <Divider orientation="left" plain className={styles.dividerContainer}>
            <ShoppingFilled />
            <span> Order Status</span>
          </Divider>

          <Form.Item 
            label="Order Status" 
            name="orderStatus"
          >
            <Cascader
              className = {styles.customCascaderStyles }
              dropdownMenuColumnStyle={{maxWidth: "250px"}}
              expandTrigger="hover"
              options={[
                {
                  label: "Non Order - No Transfer",
                  value: "Non Order - No Transfer",
                  children: [
                    {
                      label: (
                        <Tooltip placement="top" title="Wants to order/but CAN NOT due to store emergency situation/site-wide tool issue">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> CEI – No Transfer</span>
                        </Tooltip>
                      ),
                      value: "CEI, CEI – No Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Asks about menu items/prices & phone specials">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Shopping Around - No Transfer</span>
                        </Tooltip>
                      ),
                      value: "Shopping Around - No Transfer"
                    },
                    {
                      label:(
                        <Tooltip placement="top" title="Asks about online ordering & online specials">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Online Promo and Orders - No Transfer</span>
                        </Tooltip>
                      ),
                      value: "Online Promo and Orders - No Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Asks about store location/hours, delivery area & wait time">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Delivery, Store, Location, Hours & Wait Time - No Transfer</span>
                        </Tooltip>
                      ),
                      value: "Delivery, Store, Location, Hours & Wait Time - No Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Asks about jobs or contacting corporate">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Corporate Inquiries - No Transfer</span>
                        </Tooltip>
                      ),
                      value: "Corporate Inquiries - No Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Complains about teammate">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Call Center Complaint - No Transfer</span>
                        </Tooltip>
                      ),
                      value: "Call Center Complaint - No Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Wants to order but DOES NOT due to hours/delivery area/allergies">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Unserviceable Order - No Transfer</span>
                        </Tooltip>
                        ),
                      value: "Unserviceable Order - No Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Disconnects or hangs up in first 15 secs">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Disconnect/Hang Up in First 15 Secs - No Transfer</span>
                        </Tooltip>
                        ),
                      value: "Disconnect/Hang Up in First 15 Secs - No Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Disconnects or hangs up AFTER 15 secs but BEFORE readback">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Disconnect/Hang Up During Order Before Readback - No Transfer</span>
                        </Tooltip>
                        ),
                      value: "Disconnect/Hang Up During Order Before Readback - No Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Ghost/Prank Call - No Transfer">
                          <span  style={{whiteSpace: "normal"}}><Badge color="#000000" /> Ghost/Prank Call - No Transfer</span>
                        </Tooltip>
                        ),
                      value: "Ghost/Prank Call - No Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Private/Blocked Number - No Transfer">
                          <span  style={{whiteSpace: "normal"}}><Badge color="#000000" /> Private/Blocked Number - No Transfer</span>
                        </Tooltip>
                        ),
                      value: "Private/Blocked Number - No Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Inappropriate/Abusive Caller - No Transfer">
                          <span  style={{whiteSpace: "normal"}}><Badge color="#000000" /> Inappropriate/Abusive Caller - No Transfer</span>
                        </Tooltip>
                        ),
                      value: "Inappropriate/Abusive Caller - No Transfer",
                    },
                  ],
                },
                {
                  label: "Non Order - Transferred",
                  value: "Non Order - Transferred",
                  children: [
                    {
                      label: (
                        <Tooltip placement="top" title="Asks to speak to Spanish TM">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Spanish-Speaking Customer - Transfer</span>
                        </Tooltip>
                      ),
                      value: "Spanish-Speaking Customer - Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="DOES NOT speak English or Spanish">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Other Language Barrier - Transfer</span>
                        </Tooltip>
                      ),
                      value: "Other Language Barrier - Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Complains about previous order, store, or delivery">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Store, Order or Delivery Complaint - Transfer</span>
                        </Tooltip>
                        ),
                      value: "Store, Order or Delivery Complaint - Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Asks for order status or wants to change/cancel order">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Order Follow up, Modify, Cancel - Transfer</span>
                        </Tooltip>
                        ),
                      value: "Order Follow up, Modify, Cancel - Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Calls from employees, vendors, drivers, suppliers">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Store Staff & Vendor Inquiries - Transfer</span>
                        </Tooltip>
                        ),
                      value: "Store Staff & Vendor Inquiries - Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Wants to order/but DOES NOT due to hours/delivery area or allergies">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Unserviceable Order - Transfer</span>
                        </Tooltip>
                      ),
                      value: "Unserviceable Order - Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Wants to use employee, military, student, or senior discount/store only promo, loyalty or fundraiser/tax exempt">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Store Only Discounts & Specials - Transfer</span>
                        </Tooltip>
                        ),
                      value: "Store Only Discounts & Specials - Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="TM CANNOT place order due to portal/tool issue">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Order Portal & Tool Issue: Can't Process Order - Transfer</span>
                        </Tooltip>
                        ),
                      value: "Order Portal & Tool Issue: Can't Process Order - Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Wants to place a bulk order or request item customization the order portal CANNOT process">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Bulk or Special Requests - Transfer</span>
                        </Tooltip>
                        ),
                      value: "Bulk or Special Requests - Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Wants to use a payment method we CANNOT process (gift card, check, business account, store credit, EBT)">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Payment Methods Not Accepted - Transfer</span>
                        </Tooltip>
                      ),
                      value: "Payment Methods Not Accepted - Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Store Transfer Request - Transfer">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Store Transfer Request - Transfer</span>
                        </Tooltip>
                        ),
                      value: "Store Transfer Request - Transfer",
                    }
                  ],
                },
                {
                  label: "Incomplete Order",
                  value: "Incomplete Order",
                  children: [
                    {
                      label: (
                        <Tooltip placement="top" title="Had IVR payment issue and was transferred to the store for assistance">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Error In Payment – Transfer</span>
                        </Tooltip>
                        ),
                      value: "Error In Payment – Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Had IVR payment issue and DOES NOT proceed with the order">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Error In Payment – No Transfer</span>
                        </Tooltip>
                        ),
                      value: "Error In Payment – No Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Ends call or changes their mind DURING/AFTER readback">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Disconnect/Change Mind During or After Readback</span>
                        </Tooltip>
                        ),
                      value: "Disconnect/Change Mind During or After Readback",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Wants to use an unavailable or discontinued coupon and changes their mind DURING/AFTER readback">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Coupon-Related Issue - No Transfer</span>
                        </Tooltip>
                        ),
                      value: "Coupon-Related Issue - No Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Wants to use an unavailable or discontinued coupon and is transferred to the store for assistance">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Coupon-Related Issue - Transfer</span>
                        </Tooltip>
                        ),
                      value: "Coupon-Related Issue - Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Wants to order but unsuccessful due to store's cash limit">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Max Cash Limit/Can’t Pay Now - No Transfer</span>
                        </Tooltip>
                        ),
                      value: "Max Cash Limit/Can’t Pay Now - No Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Wants to order but unsuccessful due to store's cash limit; CX is transferred to the store">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Max Cash Limit/Can’t Pay Now - Transfer</span>
                        </Tooltip>
                        ),
                      value: "Max Cash Limit/Can’t Pay Now - Transfer",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Other incomplete orders for any other reason">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Other Incomplete Orders</span>
                        </Tooltip>
                        ),
                      value: "Other Incomplete Orders",
                    }
                  ],
                },
                {
                  label: "Completed Order",
                  value: "Completed Order",
                  children: [
                    {
                      label: (
                        <Tooltip placement="top" title="Wants to pay at the store; No prepay required">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Carryout - Pay at the Store</span>
                        </Tooltip>
                        ),
                      value: "Carryout - Pay at the Store",
                    },
                    {
                      label: (
                        <Tooltip placement="top" title="Wants to pay cash on delivery; No prepay required">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Delivery - Cash on Delivery</span>
                        </Tooltip>
                        ),
                      value: "Delivery - Cash on Delivery",
                    },
                    {
                      label: (
                        <Tooltip placement="top">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Carryout - Secure Pay Now System</span>
                        </Tooltip>
                        ),
                      value: "Carryout - Secure Pay Now System",
                    },
                    {
                      label: (
                        <Tooltip placement="top">
                          <span style={{whiteSpace: "normal"}}><Badge color="#000000" /> Delivery - Secure Pay Now System</span>
                        </Tooltip>
                        ),
                      value: "Delivery - Secure Pay Now System",
                    }
                  ],
                },

                // EN-2874
                // {
                //   label: "Failed Attempt Secure Pay Now",
                //   value: "Failed Attempt Secure Pay Now",
                //   children: [
                //     {
                //       label: (
                //         <Tooltip placement="top" title="Calls back after a failed payment attempt and DOES NOT place an order">
                //           <span style={{ whiteSpace: "normal" }}><Badge color="#000000" /> Failed Attempt Secure Pay Now - Non Order</span>
                //         </Tooltip>
                //       ),
                //       value: "Failed Attempt Secure Pay Now - Non Order",
                //     },
                //     {
                //       label: (
                //         <Tooltip placement="top" title="Calls back after a failed payment attempt and places an order">
                //           <span style={{ whiteSpace: "normal" }}><Badge color="#000000" /> Failed Attempt Secure Pay Now - Completed</span>
                //         </Tooltip>
                //       ),
                //       value: "Failed Attempt Secure Pay Now - Completed",
                //     },
                //   ],
                // }
              ]}
              placeholder="Please select a disposition"
            />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              sm: { offset: 9 },
              md: { offset: 9 },
              lg: { offset: 7 },
            }}
          >
            <Button
              ref={submitButton}
              type="primary"
              htmlType="button"
              block
              disabled={submitButtonDisable}
              className={styles.formButton}
              onClick={(e) => onFinish(e)}
            >
              Send
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}